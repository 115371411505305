<script setup>
import { ref } from "vue";

import Checkbox from "@/Components/Checkbox.vue";
import GuestLayout from "@/Layouts/GuestLayout.vue";
import InputError from "@/Components/InputError.vue";
import InputLabel from "@/Components/InputLabel.vue";
import GenericButton from "@/Components/GenericButton.vue";
import TextInput from "@/Components/TextInput.vue";
import { Head, Link, useForm } from "@inertiajs/vue3";
import HidePasswordIcon from '@/Icons/HidePasswordIcon.vue';
import ShowPasswordIcon from '@/Icons/ShowPasswordIcon.vue';

defineProps({
    canResetPassword: {
        type: Boolean,
    },
    status: {
        type: String,
    },
    googleProvider: {
        type: String,
        default: 'google'
    }
});

const form = useForm({
    email: "",
    password: "",
    remember: false,
});

const submit = () => {
    form.post(route("login"), {
        onFinish: () => form.reset("password"),
    });
};
const loginWithProvider = (provider) => {
    window.location.href = `/auth/${provider}`;
};

const showPassword = ref(false);

const toggleShowPassword = () => {
    showPassword.value = !showPassword.value;
}

defineOptions({ layout: GuestLayout });
</script>

<template>
    <Head class="" :title="$t('Sign in')"></Head>
    <h1
        class="px-8 text-2xl font-bold leading-9 text-primary sm:px-14"
        :title="$t('Sign in')"
    >
        {{ $t("Sign in") }}
    </h1>
    <div class="self-stretch justify-start px-8 mb-6 text-sm leading-snug sm:px-14">
        <span class="inline-block">{{ $t("New user?") }}</span>
        <span class="inline-block px-2 font-semibold text-main">
            <Link
                href="/register"
                class="text-sm font-semibold transition-all text-main hover:text-main-light"
                >{{ $t("Create a candidate account") }}</Link
            >
        </span>

        <!-- <span class="inline-block font-semibold text-main">
            <Link
                class="text-sm font-semibold text-main hover:text-main-light"
                href="/register-organization"
                >{{ $t("Create a company account") }}</Link
            >
        </span> -->
    </div>

    <div v-if="status" class="px-8 mb-4 text-sm font-medium text-green-600 sm:px-14">
        {{ status }}
    </div>

    <form @submit.prevent="submit" class="px-8 sm:px-14">
        <div>
            <InputLabel for="email" value="Email" class="block mb-1"/>

            <TextInput
                id="email"
                type="email"
                class="block w-full"
                v-model="form.email"
                required
                autofocus
                autocomplete="username"
            />

            <InputError class="mt-2" :message="form.errors.email" />
        </div>

        <div class="relative mt-4">
            <InputLabel for="password" :value="$t('Password')" class="block mb-1"/>
           <div class="relative">
				<TextInput
                id="password"
                :type="showPassword ? 'text' : 'password'"
                class="block w-full"
                v-model="form.password"
                required
                autocomplete="current-password"
            />
            <span class="absolute -translate-y-1/2 cursor-pointer right-3 top-1/2 text-secondary">
                <ShowPasswordIcon v-if="!showPassword" @click="toggleShowPassword"/>
                <HidePasswordIcon v-if="showPassword" @click="toggleShowPassword"/>
            </span>
			  </div>

            <InputError class="mt-2" :message="form.errors.password" />
        </div>
        <div class="flex content-center justify-between">
            <div class="block mt-4">
                <label class="flex items-center cursor-pointer">
                    <Checkbox name="remember" v-model:checked="form.remember" />
                    <span class="text-sm text-gray-600 ms-2">{{
                        $t("Remember me")
                    }}</span>
                </label>
            </div>
            <Link
                v-if="canResetPassword"
                :href="route('password.request')"
                class="mt-5 text-sm font-semibold transition-all text-main hover:text-main-light"
            >
                {{ $t("Forgot your password?") }}
            </Link>
        </div>


        <GenericButton
            type="submit"
            size="large"
            theme="main-contained"
            class="w-full mt-4"
            :class="{ 'opacity-25 pointer-events-none': form.processing }"
            :disabled="form.processing"
        >
            {{ $t("Log in") }}
        </GenericButton>

        <!-- <GenericButton
           type="button"
           size="large"
           theme="main-contained"
           class="w-full mt-4"
           @click="loginWithProvider(googleProvider)"
           >
           {{ $t("Login with Google") }}
           </GenericButton>
        -->
    </form>
</template>
